export const Emotes = {
  IDLE: 0,
  WALK: 1,
  RUN: 2,
  FLOAT: 3,
  FALL: 4,
  FLIP: 5,
}

export const emotes = {
  0: 'asset://emote-idle.glb',
  1: 'asset://emote-walk.glb',
  2: 'asset://emote-run.glb',
  3: 'asset://emote-float.glb',
  4: 'asset://emote-fall.glb',
  5: 'asset://emote-flip.glb',
}
